.no-padding {
  padding: 0;
}
.no-padding-left {
  padding-left: 0 !important;
}
.no-padding-right {
  padding-right: 0;
}
.no-padding-top {
  padding-top: 0;
}
.no-padding-bottom {
  padding-bottom: 0;
}
.no-padding-left-md {
  padding-left: 0;
}
.no-padding-right-md {
  padding-right: 0;
}
.no-margin {
  margin: 0;
}
.no-margin-left {
  margin-left: 0;
}
.no-margin-right {
  margin-right: 0;
}
.no-margin-top {
  margin-top: 0;
}
.no-margin-bottom {
  margin-bottom: 0;
}
.margin-center {
  margin-left: auto;
  margin-right: auto;
}
.no-border {
  border: 0 none;
}
.no-border-radius {
  border-radius: 0;
}
.bg-transparent {
  background-color: transparent;
}
.section-control {
  display: table;
  width: 100%;
}
.cover {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0
}
.vertical-center {
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  position: relative;
}
.horizontal-center {
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  position: relative;
}
.absolute-center {
  padding-left: 0;
  padding-right: 0;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  position: absolute;
  left: 50%;
}
.transition {
  -webkit-transition: all 0.25s ease-in-out 0s;
  transition: all 0.25s ease-in-out 0s;
}
/* ==========================================================================
Author's default styles
========================================================================== */

.steps-control {
  background-image: url("/images/bg-step-control.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 105px;
  margin-left: auto;
  margin-right: auto;
  padding: 35px 30px;
}
.steps-control li {
  color: #333;
  float: left;
  font-family:  sans-serif;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.1em;
  line-height: 16px;
  padding: 0;
  text-transform: uppercase;
  width: 19%;
  margin-left: 30px;
}
.steps-control li.active {
  color: #0073b0;
}
.steps-control span {
  float: left;
  font-size: 11px;
  letter-spacing: 1.5px;
  padding-left: 10px;
  width: 85px;
}
li.active .number {
  background-color: #0073b0;
}
li.completed {
  color: #999;
}
.steps-control li span:last-child {
  margin-top: 2px;
}
.step-two,
.step-one {
  margin-bottom: 30px;
}
.step-two,
.step-one,
.step-three {
  margin-top: 0.5em;
}
.steps-control .number {
  background-color: #999;
  border-radius: 50%;
  color: white;
  font-family:  sans-serif;
  font-size: 18px;
  font-weight: 900;
  height: 35px;
  line-height: 22px;
  padding-top: 6px;
  position: relative;
  text-align: center;
  width: 35px;
}
.steps-wrapper h1 {
  color: #0073b0;
  font-family:  sans-serif;
  font-size: 2.2rem;
  font-weight: 400;
  margin-bottom: 5px;
  margin-top: 45px;
  letter-spacing: -0.5px;
}
.steps-wrapper small {
  color: dimgrey;
  display: block;
  font-family: sans-serif;
  font-size: 14px;
  font-weight: 300;
  margin-left: auto;
  margin-right: auto;
  width: 66%;
}
.business-details > small {
  display: block;
  margin-bottom: 30px;
  margin-top: 5px;
}
.steps-wrapper > p {
  color: #333;
  font-family:  sans-serif;
  font-size: 22px;
  font-weight: 400;
  line-height: 1.3;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2.5rem;
  width: 70%;
}
.business-type {
  margin-top: 40px;
  padding: 20px 0;
  text-align: center;
}
.business-type img {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.multi-location {
  margin-left: auto;
  margin-right: auto;
}
.small-control {
  width: 122px;
  -webkit-transition: all 0.25s ease-in-out 0s;
}
.large-control {
  width: 122px;
}
.box {
  background-color: white;
  border-radius: 5px;
}
.business-type span {
  display: block;
  font-family: sans-serif;
  font-size: 11px;
  font-weight: 600;
  letter-spacing: 1.3px;
  line-height: 1.35;
  margin-top: 20px;
  transition: all 0.25s ease-in-out 0s;
}
.box-sm {
  box-shadow: 0 2px 6px rgba(51, 51, 51, 0.1);
  height: 122px;
  margin-top: 5px;
  -webkit-transition: all 0.25s ease-in-out 0s;
  transition: all 0.25s ease-in-out 0s;
}
.on-select .inactive-icon1, .location:hover .inactive-icon1 {
  fill: #f5d3d7;
}
.on-select .inactive-icon2, .location:hover .inactive-icon2 {
  fill: #dd626e;
}
.location:hover .box-sm, .on-select .box {
  box-shadow: 0 6px 15px rgba(51, 51, 51, 0.2);
  position: relative;
}
.single-scale {
  width: 35.875px;
  height: 50.563px;
}
.icon-status {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transition: all 0.25s ease-in-out 0s;
  transition: all 0.25s ease-in-out 0s;
}
.multi-scale {
  width: 55.656px;
  height: 52.188px;
}
.market-scale {
  width: 49.938px;
  height: 49.938px;
}
.on-select .single-scale, .location:hover .single-scale {
  width: 41.875px;
  height: 56.563px;
}
.on-select .market-scale, .location:hover .market-scale {
  width: 55.938px;
  height: 55.938px;
}
.on-select .multi-scale, .location:hover .multi-scale {
  width: 61.656px;
  height: 58.188px;
}
.location {
  cursor: pointer;
}
.on-select span, .location:hover span {
  color: #dd626e;
}
.close-model {
  fill: #005785;
  fill-rule: evenodd;
}
.close {
  opacity: 1;
  position: fixed;
  right: 40px;
  top: 20px;
}
.small-control {
  color: #999;
}
.box-lg {
  height: 132px;
  border: 1px solid #ededed;
  box-shadow: 0 6px 15px rgba(51, 51, 51, 0.2);
}
.half-border {
  border-bottom: 1px solid #cdcdcd;
  border-left: 1px solid #cdcdcd;
  border-radius: 0 0 4px 4px;
  border-right: 1px solid #cdcdcd;
  height: 10px;
  margin-top: -10px;
  width: 100%;
}
.input-with-label-group{
  clear: both;
}
.input-with-label-group span{
  position: absolute;
  left: 8%;
  top: 38px;
  margin-left: 2px;
  font-family: sans-serif;
  font-size: 13px;
  font-weight: 300;
  height: 34px;
}

.input-with-label-group input{
  padding-left: 20%;
}
.input-with-label-group select{
  position: absolute;
  left: 8%;
  top: 37px;
  margin-left: 0px;
  font-size: 13px;
  width: 14%;
  border: none;
}
.input-with-label-group .alert-call{
  font-family: sans-serif;
  font-size: 12px;
  position: absolute;
  top: 100%;
}

.form-control {
  border: medium none;
  box-shadow: none;
  color: #000;
  font-family: sans-serif;
  font-size: 13px;
  font-weight: 300;
  height: 34px;
  opacity: 1;
  padding: 0 14px;
  background-color: transparent;
}
.form-control:focus {
  border-color: transparent;
  box-shadow: none;
}
.steps-wrapper label {
  color: #0073b0;
  font-family: sans-serif;
  font-size: 11px;
  font-weight: 500;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  margin-bottom: 10px;
  margin-left: 20px;
  margin-right: 20px;
}

.whitelabel-filter {
  display: inline-block;
  padding: 0 70px;
  /* For browsers that do not support gradients */
  background: -webkit-linear-gradient(left, rgba(224, 239, 247, 0), rgba(224, 239, 247, 0), rgba(224, 239, 247, 0.3), rgba(224, 239, 247, 0.5), rgba(224, 239, 247, 0.5), rgba(224, 239, 247, 0.5), rgba(224, 239, 247, 1), rgba(224, 239, 247, 0.5), rgba(224, 239, 247, 0.5), rgba(224, 239, 247, 0.5), rgba(224, 239, 247, 0.3), rgba(224, 239, 247, 0), rgba(224, 239, 247, 0));
  /*Safari 5.1-6*/
  background: -o-linear-gradient(left, rgba(224, 239, 247, 0), rgba(224, 239, 247, 0), rgba(224, 239, 247, 0.3), rgba(224, 239, 247, 0.5), rgba(224, 239, 247, 0.5), rgba(224, 239, 247, 0.5), rgba(224, 239, 247, 1), rgba(224, 239, 247, 0.5), rgba(224, 239, 247, 0.5), rgba(224, 239, 247, 0.5), rgba(224, 239, 247, 0.3), rgba(224, 239, 247, 0), rgba(224, 239, 247, 0));
  /*Opera 11.1-12*/
  background: -moz-linear-gradient(left, rgba(224, 239, 247, 0), rgba(224, 239, 247, 0), rgba(224, 239, 247, 0.3), rgba(224, 239, 247, 0.5), rgba(224, 239, 247, 0.5), rgba(224, 239, 247, 0.5), rgba(224, 239, 247, 1), rgba(224, 239, 247, 0.5), rgba(224, 239, 247, 0.5), rgba(224, 239, 247, 0.5), rgba(224, 239, 247, 0.3), rgba(224, 239, 247, 0), rgba(224, 239, 247, 0));
  /*Fx 3.6-15*/
  background: linear-gradient(to right, rgba(224, 239, 247, 0), rgba(224, 239, 247, 0), rgba(224, 239, 247, 0.3), rgba(224, 239, 247, 0.5), rgba(224, 239, 247, 0.5), rgba(224, 239, 247, 0.5), rgba(224, 239, 247, 1), rgba(224, 239, 247, 0.5), rgba(224, 239, 247, 0.5), rgba(224, 239, 247, 0.5), rgba(224, 239, 247, 0.3), rgba(224, 239, 247, 0), rgba(224, 239, 247, 0));
  /*Standard*/
}

.form-group {
  margin-bottom: 0;
  margin-top: 40px;
}
.btn-status {
  background-color: #10bc47;
  border: medium none;
  border-radius: 3px;
  color: white;
  font-family: sans-serif;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 2px;
  line-height: 0.8;
  margin-top: 50px;
  padding: 18px 22px;
  transition: all 0.25s ease-in-out 0s;
}
.btn-status:hover {
  color: white;
  background-color: #14A929;
  border: none;
}
.btn-status:active:focus, .btn-status:focus, .btn-status:active {
  color: white;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, .3);
  background-color: #14A929;
}
.steps-wrapper form > button {
  margin-top: 40px;
}
.form-control::-moz-placeholder {
  color: #999;
  opacity: 0.5;
}

/*step-two-page*/
.steps-wrapper span.tick {
  opacity: 0.5;
  background-color: #10bc47;
  padding-left: 8px;
  padding-top: 3px;
  text-align: right;
}
.step-two > p {
  color: #333;
  font-family: sans-serif;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.1;
  margin-left: auto;
  margin-right: auto;
  margin-top: 3.4375rem;
  text-align: left;
  width: 66%;
}
.step-two small {
  text-align: left;
}
.step-two select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("/images/bg-combobox-arrow.png");
  background-position: 98% center;
  background-repeat: no-repeat;
  background-color: transparent;
}
.step-three {
  margin-bottom: 0;
}
.combo-box-arrow {
  width: 98%;
  background-position: right center;
}
.each-listner-details {
  float: left;
  line-height: 1;
  padding-right: 20px;
  width: calc(100% - 140px);
}
.each-listner-details > span {
  color: #333;
  font-family: sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.3;
}

.wrapperVA {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

.wrapperVA > img {
  width: 20px;
  margin-right: 8px;
}

.wrapperVA > h3 {
  font-size: 18px;
  margin: 0;
}

.wrapperVA > p {
  margin-bottom: 0;
}

.each-listner-details > h3 {
  color: #333;
  font-family: sans-serif;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 10px;
  margin-top: 4px;
}
.user-address, .user-phone{
  font-size: 14px;
  font-family: sans-serif;
}
.each-listner-profile {
  line-height: 1;
  width: 100px;
  padding-right: 20px;
}
.listner-logo {
  margin-bottom: 10px;
  width: 36px;
  height: 36px;
}
.listner-logo > img {
  max-width: 100%;
  height: auto;
}
.each-listner-result {
  height: 76px;
  width: 30px;
}
.each-listner-profile > a {
  color: #3596da;
  font-family: sans-serif;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.each-listner-result.pull-right > a {
  display: block;
  height: 30px;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
.each-listner-profile > span {
  color: #333;
  display: block;
  font-family: sans-serif;
  font-size: 15px;
  font-weight: 400;
  margin-bottom: 5px;
}
.scan-listing {
  background-color: white;
  border: 1px solid rgba(102, 102, 102, 0.5);
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(136, 136, 136, 0.2);
  padding: 12px;
  margin-bottom: 20px;
  min-height: 120px;
}
.bg-green {
  background-color: #10bc47;
  border-radius: 3px;
}
.fix-loction {
  color: white;
  display: inline-block;
  font-family: sans-serif;
  font-size: 2.25rem;
  font-weight: 400;
  padding-bottom: 15px;
  padding-top: 15px;
}
.btn-continue {
  border: 2px solid white;
  border-radius: 25px;
  color: white;
  font-family: sans-serif;
  font-weight: 900;
  letter-spacing: 0.1em;
  margin-left: 30px;
  padding: 10px 25px;
  position: relative;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.btn-continue:active:focus, .btn-continue:focus, .btn-continue:active {
  color: #10bc47;
  text-decoration: none;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, .3);
  background-color: #eaeaea;
}
.green-tick {
  fill: #fff;
  fill-rule: evenodd;
}
.btn-continue:hover .green-tick {
  fill: #10bc47;
  fill-rule: evenodd;
}
.tick-success {
  margin-right: 10px;
}
.btn-continue:hover {
  background-color: white;
  color: #10bc47;
}
a.yellow {
  color: #f8a600;
}
.fix-location-wrapper {
  margin-top: 30px;
}
.white-tooltip + .tooltip .tooltip-inner, .white-tooltip + .tooltip .tooltip-arrow {
  background-color: white;
}
.white-tooltip + .tooltip .tooltip-arrow {
  top: 30px;
  border-bottom: green 1px solid;
}
.failer-tooltip + .tooltip .tooltip-inner {
  color: #ed4848;
}
.success-tooltip + .tooltip .tooltip-inner {
  color: #10bc47;
}
.warring-tooltip + .tooltip .tooltip-inner {
  color: #f8ac12;
}
.white-tooltip + .tooltip > .tooltip-inner {
  background-color: white;
}
.white-tooltip + .tooltip > .tooltip-arrow {
  display: none;
}
.tooltip-inner {
  width: 170px;
}
.close > span {
  color: #005785;
  font-size: 40px;
}
.tooltip {
  background-color: #fff;
  border-radius: 3px;
  border: 1px solid #bcbcbc;
  box-shadow: 0 3px 5px rgba(140, 140, 140, 0.2);
  color: #ed4848;
  font-size: 13px;
  font-weight: 400;
  font-family: sans-serif;
  max-width: 350px;
}
.fade.in {
  overflow-x: hidden;
  overflow-y: auto;
}
.chart-analyse {
    background-color: #f8f8f8;
    border: 1px solid #e4e5e5;
    display: table;
    float: left;
    padding: 30px 20px 0 10px;
    position: relative;
    width: 38%;
}
.current-list > h3 {
  border-bottom: 1px solid #e5e5e5;
  border-top: 1px solid #e5e5e5;
  color: #666;
  font-family:  sans-serif;
  font-size: 13px;
  letter-spacing: 0.15em;
  padding: 15px 20px;
}
.chart-wrapper {
  margin-bottom: 30px;
  margin-top: 30px;
}

#canvas-holder {
  position: relative;
  width: 220px;
  height: 220px;
  text-align: center;
  float: left;
}

#myChart {
  width: 56%;
  float: left;
  height: 245px;
}
.current-list {
  float: left;
  width: 34%;
  background-color: white;
}
.business-details h3 {
  color: #0073b0;
  font-size: 13px;
  letter-spacing: 0.15em;
  margin-bottom: 18px;
}
.current-rate {
  float: left;
  line-height: 1;
  padding: 0 25px;
  width: 50%;
  border-bottom: 1px solid #e5e5e5;
}
.current-rate span {
  font-family:  sans-serif;
  font-size: 3rem;
  font-weight: 400;
  letter-spacing: -2px;
}
.current-rate p {
  color: #666;
  font-family: sans-serif;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 0;
}
.inaccurate span {
  color: #df0000;
}
.inaccurate, .found {
  border-right: #e5e5e5 1px solid;
}
.accurate span {
  color: #4ec353;
}
.found span {
  color: #a5a5a5;
}
.total-site span {
  color: #444;
}
.scan-child:nth-child(3n+1) {
  clear: both;
  /*padding-left: 0;*/
}
.scan-listing-wrapper {
    /*margin-left: -15px;
    margin-right: -15px;*/
    width: calc(100% + 30px);
    margin-bottom: 7em;
}
.scan-child:nth-child(2n+1) {
  /*padding-right: 0;*/
}
.business-details span {
  color: #333;
  display: block;
  font-family: sans-serif;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0.5px;
}
.business-details small a {
  color: #999;
  font-family: sans-serif;
  font-size: 13px;
  font-weight: 300;
  line-height: 24px;
  text-decoration: underline;
}
a.btn-link {
  background-color: transparent;
  border: 2px solid #999;
  border-radius: 25px;
  color: #999;
  font-family:  sans-serif;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.1em;
  padding-left: 20px;
  padding-right: 20px;
}
a.btn-link:hover {
  border: 2px solid #999;
  background-color: #999;
  color: #fff;
  text-decoration: none;
}
.no-text-underline {
  text-decoration: none;
}
a.btn-link:active:focus, a.btn-link:focus, a.btn-link:active {
  color: #fff;
  text-decoration: none;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, .3);
  background-color: #cdcdcd;
}
.business-details {
  background-color: #f8f8f8;
  border: 1px solid #e5e5e5;
  float: left;
  padding: 25px 30px 0;
  width: 28%;
}
.chart-details {
  color: #d90000;
  float: left;
  font-family:  sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.2;
  padding-left: 25px;
  padding-top: 20px;
  text-align: right;
  width: 43%;
}

.chart-details .metric {
  font-size: 22px;
  font-weight: 500;
}

.chart-details a {
  display: inline-block;
  margin-top: 10px;
}

.btn-fix {
  font-size: 16px;
  font-weight: 900;
  letter-spacing: 0.2em;
  margin-top: 15px;
}
.inactive-icon1 {
  fill: #ededed;
}
.inactive-icon1, .inactive-icon2 {
  fill-rule: evenodd;
}
.inactive-icon2 {
  fill: #9a9a9a;
}
.vertical-center-custom {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
.percentage-wrapper{
  width: 57%;
  float: left;
}
.percentage {
  position: absolute;
  z-index: 0;
  top: 50%;
  left: 50%;
  width: 140px;
  height: 140px;
  background-color: white;
  border: 3px solid #fff;
  border-radius: 50%;
  /*box-shadow: 0 0 13px rgba(116, 116, 116, 1) inset;*/
  color: #e30000;
  font-family: sans-serif;
  font-size: 46px;
  font-weight: 400;
  letter-spacing: -3px;
  line-height: 16px;
  opacity: 1;
  padding-top: 53px;
  text-align: center;
  transform: translate(-50%, -50%);
}

.percentage span {
  color: #333;
  display: block;
  font-size: 23px;
  font-weight: 400;
  letter-spacing: -1px;
  margin-top: 8px;
}

span.pbar-text1 {
  font-family: sans-serif;
  color: #666666;
  font-size: 14px;
  font-weight: 600;
  margin-top: -14px;
  letter-spacing: normal;
}

span.pbar-count {
  font-family: sans-serif;
  color: #0e90d4;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: normal;
}

span.pbar-text2 {
  font-family: sans-serif;
  color: #0e90d4;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: normal;
}

.form-control::-webkit-input-placeholder, .form-control:-moz-placeholder, .form-control::-moz-placeholder, .form-control:-ms-input-placeholder, .form-control::-ms-input-placeholder, .form-control:placeholder-shown {
  color: #999;
  opacity: 1;
}
canvas {
  border-radius: 50%;
  box-shadow: 0 2px 10px #333;
}
.alert-call {
  float: left;
  font-family: sans-serif;
  font-size: 12px;
  font-weight: 300;
  margin-top: 8px;
  padding-left: 20px;
  position: relative;
}
.success-alert {
  color: #10bc47;
}
.cross-alert {
  color: #e50000;
}
.exclamate-alert {
  color: #ff850e;
}
.alert-call::before {
  position: absolute;
  content: "";
}
.success-alert::before {
  background-image: url("../images/synup-scan-images/tick-success.svg");
  height: 8px;
  left: 2px;
  top: 4px;
  width: 10px;
}
.cross-alert::before {
  background-image: url("../images/synup-scan-images/cross-error.svg");
  height: 8px;
  left: 3px;
  top: 5px;
  width: 8px;
}
.exclamate-alert::before {
  background-image: url("../images/synup-scan-images/exclam-alert.svg");
  height: 10px;
  left: 6px;
  top: 4px;
  width: 3px;
}
.form-group-wrapper .single-group {
  width: 100%;
  margin-left: 0;
}
input[type=radio] {
  display: none;
}
.page-steps .navbar {
  padding: 0;
}
.page-steps .navbar-wrapper a {
  color: rgba(25, 61, 107, 0.7);
}
.page-steps .navbar-wrapper {
  position: relative;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}
.page-steps .navbar-nav > li > a.login {
  border: 2px solid rgba(25, 61, 107, 0.7);
  border-radius: 16px;
  color: rgba(25, 61, 107, 0.7);
  margin: 0;
  padding: 5px 20px;
}
.page-steps .navbar {
  min-height: 0;
}
.page-steps .navbar-brand {
  background: rgba(0, 0, 0, 0) url("/images/logo-header.png") no-repeat scroll center center;
}
footer {
  clear: both;
}
.page-steps #navbar a:active,
.page-steps #navbar a:focus,
.page-steps #navbar a:hover {
  color: rgba(25, 61, 107, 1);
}

.listing-missing-label {
  color: #ff850e !important;
  text-transform: uppercase;
  margin-top: 30px !important;
}

.listing-error-label {
  color: #e60000 !important;
  text-transform: uppercase;
  margin-top: 30px !important;
}

.listing-synced-label {
  color: #4ec353 !important;
  text-transform: uppercase;
  margin-top: 30px !important;
}

.listing-inprogress-label {
  color: #458de7 !important;
  text-transform: uppercase;
  margin-top: 30px !important;
}

.scan-tool-header-wrapper {
  margin-top: 20px;
  text-align: center;
}

.scan-tool-business-logo {
  max-height: 80px;
  max-width: 200px;
}

.synup-logo {
  margin-top: 1.25em;
}

.add-content::before, .add-content::after{
    content: '';
    display: table;
    clear: both;
}

div.chart-wrapper, div.scan-listing-wrapper, div.scan-listing{
  overflow: auto;
  display: block;
}
.searching{
  font-size: 14px;
  text-transform: uppercase;
}
.searching-cont{
  line-height: 92px;
}
@media screen and (max-width: 1199px) {
  .business-details {
    border-top: medium none;
    padding-bottom: 25px;
    text-align: center;
    width: 100%;
  }
  .chart-analyse{
    width: 62%;
    float: left;
  }
  .current-list{
    width: 38%;
    float: left;
    border-right: #e5e5e5 1px solid;
  }

  .current-rate {
    padding: 0 0 0 25px;
  }

  .current-rate p {
    font-size: 13px;
  }

  .current-rate span {
    font-size: 2.25rem;
  }

}


@media only screen and (max-width: 480px) {
  .scan-listing-wrapper {
    margin: 0 auto 5em auto !important;
  }
  .input-with-label-group span{
    top: 34px;
  }
  .input-with-label-group select {
    left: 7%;
    top: 34px;
    font-size: 13px;
    width: 13%;
  }
}

@media print {
  a[href]:after {
    content: none !important;
  }
  .scan-result-text{
    display: block !important;
  }
  .chart-analyse{
    background-color: rgb(255, 241, 241) !important;
    border-color: rgb(255, 208, 208) !important;
  }
  .business-details{
    background-color: #f8f8f8 !important;
    border: 1px solid #e5e5e5;
  }
  .view-listing-link{
    color: #0073b0 !important;
  }
  .chart-details > span, div.percentage, .inaccurate span{
    color: #d90000 !important;
  }
  .accurate span {
      color: #4ec353 !important;
  }
  .found span {
    color: #a5a5a5 !important;
  }
  .total-site span {
    color: #444 !important;
  }
  .each-listner-details{
    padding-left: 150px !important;
  }
  .scan-child{
    width: 100%;
  }
  .each-listner-details > h3{
    font-weight: 100 !important;
    font-size: 16px !important;
  }
  .scan-listing{
    margin-bottom: 15px !important;
  }
  .user-address, .user-phone, .each-listner-details>h3 {
    font-size: 18px !important;
    font-weight: 300 !important;
  }
  .btn-fix {
    width: 25mm !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    margin-top: 15px !important;
    margin-bottom: 0 !important;
    border: 2px solid #10bc47 !important;
    background-color: #10bc47 !important;
    border-radius: 3px !important;
    color: #fff !important;
    letter-spacing: 2px !important;
    line-height: .8 !important;
    padding: 8px 10px !important;
    transition: all .25s ease-in-out 0s !important;
    display: inline-block !important;
    text-align: center !important;
    white-space: nowrap !important;
    vertical-align: middle !important;
    -ms-touch-action: manipulation !important;
    touch-action: manipulation !important;
    cursor: pointer !important;
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
    background-image: none !important;
    border-radius: 4px !important;
  }
  #canvas-holder{
    width: 42mm !important;
    height: 42mm !important;
  }
  #chart-area{
    width: 41mm !important;
    height: 41mm !important;
  }
  .percentage{
    width: 28mm !important;
    height: 28mm !important;
    font-size: 36px !important;
    padding-top: 32px !important;
  }
}
@page {
  size: auto;
  margin-top: 0mm;
  margin-bottom: 0mm;
}

/* Reviews CSS */
#recent-reviews{
  background-color: #F5F5F5;
  padding-top: 10px;
  padding-bottom: 100px;
}

.no-reviews{
  background-color: white !important;
  text-align: left;
  padding-left: 0;
  font-size: 20px;
  font-weight: 500;
  color: #333;
}

.no-reviews .spinner{
  display: block;
  margin: 10px auto;
}

.review-container{
  background-color: #fff;
  margin: 5px 0;
  padding: 25px 15px;
  box-shadow: 2px 2px #EDEDED;
}

.author-data{
  font-weight: 500;
  font-size: 14px;
}

.author-name{
  font-weight: 500;
  font-size: 14px;
}

.review-date{
  display: block;
  font-size: 12px;
  color: #666;
  margin-top: 2px;
  font-weight: 500;
}

.review-star{
  margin-top: 5px;
  color: #0082CE;
}
.review-response{
  background-color: #EBFFF1;
  color: #66C985;
  padding: 4px 10px;
  width: 54%;
  margin-top: 5px;
  border-radius: 3px;
}
.review-response.pending{
  background-color: #F5F5F5;
  color: #666;
  display: block;
  width: 62%;
}
.review-site-image{
  height: 25px;
  width: 25px;
  position: relative;
  left: 0px;
  top: 15%;
}
.results-switch{
  border: 2px solid #64B9E7;
  font-size: 20px;
  border-radius: 11px;
  padding: 7px 7px;
  margin-bottom: 20px;
  height: 66px;
  clear: both;
}
.results-switch a{
  display: block;
  padding: 10px;
  width: 49%;
  text-align: center;
  border-radius: 7px;
}
.results-switch a.selected{
  color: white;
  background-color: #0073B0;
}
span.headers, .headers{
  display: block;
  font-size: 10px;
  font-weight: 700;
  color: #666;
  letter-spacing: 1px;
  max-width: 90px;
  margin: 0 auto;
}
#review-summary{
  margin-top: 30px;
  text-align: center;
  padding: 0px;
  margin-bottom: 30px;
}
.summary-result{
  border: 1px solid #EDEDED;
  display: block;
  padding-top: 16px;
  padding-bottom: 16px;
  margin: 10px 0;
  box-shadow: 2px 2px #EDEDED;
  line-height: 2;
}
.site-text, .site-value{
  color: #222;
  font-weight: 500;
  font-size: 16px;
  margin: 0 auto;
}
.site-text{
  text-align: left;
  padding-left: 10px;
  font-weight: 500;
}
.site-value{
  color: #0073B0;
}
.reviews-heading{
  padding-left: 0;
}
.popover__wrapper__cont{
  position: relative;
}
.popover__wrapper {
  display: inline-block;
}
.popover__content {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  left: 100%;
  top: -65%;
  transform: translate(0,10px);
  border: 1px solid #eee;
  padding: .8rem 1rem;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  width: auto;
  color: #666;
  width: 330px;
  letter-spacing: 0;
  background-color: #fff;
  text-align: left;
  line-height: 1.4;
  font-size: 13px;
}
.popover__wrapper:hover .popover__content {
  z-index: 10;
  opacity: 1;
  visibility: visible;
  transition: all 0.5s cubic-bezier(0.75, -0.02, 0.2, 0.97);
}
.popover__message {
  text-align: center;
}
.tooltip-circle{
  background: #fff;
  color: #0073B0;
  border: 1px solid #0073B0;
  border-radius: 50%;
  width: 16px;
  height: 15px;
  display: inline-block;
}
.summary-result .spinner{
  display: block;
  height: 20px;
  width: 20px;
  margin: 0 auto;
}
.summary-result .review-site-image{
  height: 30px;
  width: 30px;
  position: relative;
  left: -8%;
  top: 0%;
}
.review-content{
  line-height: 1.5;
  font-weight: 500;
  font-size: 15px;
}
@media (max-width: 800px) {
  .results-switch{
    width: 80%;
    clear: both;
    margin: 20px auto;
  }
  .popover__content {
    left: inherit;
    right: 100%;
  }
}

@media (max-width: 560px){
  .results-switch a{
    font-size: 12px;
    padding: 5px;
  }

  .results-switch{
    height: 40px;
    padding: 5px;
  }

  #reviews-button{
    width: 50%;
    padding: 4px;
  }

  .site-value, .site-text{
    font-size: 12px;
  }

  .steps-control li {
    margin-left: 15px;
  }
}

.accurate-dot {
  height: 15px;
  width: 15px;
  background-color: #1fbe4b;
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;
}

.inaccurate-dot {
  height: 15px;
  width: 15px;
  background-color: #e60001;
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;
}

.missing-dot {
  height: 15px;
  width: 15px;
  background-color: #f18b37;
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;
}

.tippy-popper .tippy-tooltip{
  text-align: left;
  border: 1px solid #cecece;
  padding: 0px;
  margin-top: 2em;
  margin-bottom: 2em;
}

.tippy-content .row {
  margin-right: 0px;
  margin-left: 0px;
}

.tippy-content {
  padding: 12px;
}

.tippy-content  .col-sm-12 {
  padding-right: 0px;
  padding-left: 0px;
}

.tippy-content  .col-sm-2 {
  padding-right: 0px;
  padding-left: 0px;
  width: 25px;
}

.tippy-content  .col-sm-10 {
  padding-right: 0px;
  padding-left: 0px;
  width: calc(100% - 25px);
  margin-top: 2px;
  margin-bottom: 2px;
}

.tippy-content .inaccurate-dot {
  vertical-align: text-bottom;
}

.navbar-fixed-bottom {
  z-index:10000;
}

/* .tippy-content .col-sm-12 .row:not(:first-child) {
  margin-top: 10px;
} */
